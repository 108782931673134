import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "components/Layout/Layout"
import MaxContainer from "components/shared/MaxContainer"
import SliderWithText from "components/shared/SliderWithText"
import SEO from "utils/Seo"

const List = styled.ul`
	padding-left: 20px;
	padding-top: .5rem;
	margin: 0;
	li {
		h2 {
			font-size: ${props => props.theme.font.size.base};
			margin: 0;
		}
	}
`

const IndexPage = () => (
	<StaticQuery
		query={graphql`{
  allHomeImagesJson {
    edges {
      node {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        alt
      }
    }
  }
}
`}
		render={data => (
			<IndexBlock images={data.allHomeImagesJson.edges} />
		)}
	/>
)
		
const IndexBlock = (props) => (
	<Layout>
		<SEO title="Plantlife Landscapes | Landscape Gardener | Garden Design" description="Plantlife Landscapes will provide a professional and creative garden design service that is hard to come by. We would like to work with you to enable you to realise the full potential of your outside space, from a small feature through to a full design & build service."/>
		<MaxContainer>
			<SliderWithText images={props.images}>
				<h1>Garden Design and Landscapers in Devon & Cornwall</h1>
				<h2>Professional Landscape gardeners</h2>

				<p>Plantlife landscapes is managed by owner Owen Clarke who is an experienced landscaper, garden designer and horticulturist based in Cornwall. Holding an N.D in Horticulture and a B.A.(Hons) in Landscape Architecture, Owen and his team have a passion for the outdoors.</p>

				<p>We would like to work with you to enable you to realize the full potential of your outside space, from a small feature to a full design and build service.</p>

				<p>By offering a fully integrated service it enables us to provide creative garden design solutions and control the build quality and attention to detail that is key to a successful scheme.</p>

				<p>If your garden is large or small, or you only need assistance with a particular section, Plantlife Landscapes will provide a professional and creative garden design service that is hard to come by. Unlike other garden designers we provide transparency when it comes to <Link to="/costs/">prices</Link> so you know how much things will cost from start to finish.</p>

				<p>Our garden design and landscape architect services are provided in <Link to="/gallery/small/plymouth-town-house-small-garden/">Plymouth</Link>, <Link to="/gallery/large/exeter-medium-garden/">Exeter</Link>, <Link to="/gallery/large/tavistock-devon-garden-project/">Tavistock</Link>, Liskeard, Launceston and many other areas throughout <Link to="/gallery/large/tavistock-barn-large-garden/">Devon</Link> and <Link to="/gallery/large/stoke-climsland-cornwall-large-garden/">Cornwall</Link>.</p>

				<p>Take a look at some of the examples of completed designs in our <Link to="/gallery/large/tavistock-devon-garden-project/">gallery</Link> and please feel free to <Link to="/contact">contact</Link> us for our friendly advice and a free initial consultation.</p>
			</SliderWithText>
		</MaxContainer>
	</Layout>
)
	
export default IndexPage
